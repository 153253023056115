import React from "react";
import { graphql } from "gatsby";
import { DefaultLayout, SidebarBody } from "../layouts";
import { Hero } from "../components";
import styled from "@emotion/styled";

const Prices = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
`;
const HeadingWrapper = styled.div`
    background: linear-gradient(to bottom, #6a767f 0%,#5c676f 50%,#303030 51%,#46535c 100%);
    text-align: center;
    padding: 5px 8px 0;    
    border: 2px solid #5a6771;
`;
const PricesHeading = styled.div`
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 9px;
`;
const PricesSource = styled.div`
    font-size: 10px;
    margin-bottom: 9px;
    font-family: Roboto;
    font-weight: 400;
`;
const PricesDate = styled.div`
    border: 2px solid #999999;
    border-bottom: 1px solid #660000;
    color: #000;
    font-size: 13px;
    font-family: Roboto;
    font-weight: 400;
    text-align: center;
    padding: 5px;    
    background: linear-gradient(to bottom, #dadada 0%,#aaaaaa 50%,#929292 51%,#999999 100%);
`;
const FundPrices = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-family: Roboto;
    font-weight: 400;

    @media (min-width: 760px) {
        > * {
            flex-basis: 50%;
        }
        > :nth-child(4n+1), > :nth-child(4n+2){
            background-color: black;
        }
        > :nth-child(4n), > :nth-child(4n+3){
            background-color: #292929;
        }
    }

    @media (max-width: 759px) {
        > * {
            flex-basis: 100%;
        }
        > :nth-child(2n){
            background-color: black;
        }
        > :nth-child(2n+1){
            background-color: #292929;
        }
    }
`;
const FundPrice = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1px 5px;
    text-transform: uppercase;
`;

const GroupHeader = styled.div`
    color: #360;
    font-size: 18px;
    line-height: 22px;    
`;

const Document = styled.li`
    > a {
        color: #693;    
    }
`;

export type FundPageProps = {
    pricing: {
        heading: string,
        source: string,
        date: string,
        prices: {
            name: string,
            value: string
        }[]
    },
    literatureGroups: {
        name: string,
        documents: {
            url: string,
            name: string
        }[]
    }[]
};

export const HomePageTemplate = (fund: FundPageProps) => (
    <DefaultLayout>
        <Hero showBox={true} image="/images/uploads/alternatives-header.jpg" boxText="EEA Life Settlements Fund PCC Limited" />
        <Prices>
            <HeadingWrapper>
                <PricesHeading>{fund.pricing.heading}</PricesHeading>
                <PricesSource>(SOURCE: {fund.pricing.source})</PricesSource>
            </HeadingWrapper>
            <PricesDate>DATE: {fund.pricing.date}</PricesDate>
            <FundPrices>
                {fund.pricing.prices.map((price, index) => {
                    return (<React.Fragment key={index}>
                        <FundPrice >
                            <div>{price.name}</div>
                            <div>{price.value}</div>
                        </FundPrice>
                        {index % 1 > 0 ? <br /> : <></>}
                    </React.Fragment>);
                })}
            </FundPrices>
        </Prices>
        <SidebarBody>
            <h1>EEA Life Settlements Fund PCC Limited</h1>
            <hr />
            {fund.literatureGroups.map((lg, index) => (
                <React.Fragment key={index}>
                    <GroupHeader>
                        {lg.name}
                    </GroupHeader>
                    <ol>
                        {lg.documents.map((doc, dIndex) => (
                            <Document key={dIndex}>
                                <a href={doc.url} target="blank">{doc.name}</a>
                            </Document>
                        ))}
                    </ol>
                </React.Fragment>
            ))}
        </SidebarBody>
    </DefaultLayout>
);

export const query = graphql`
  {
    fund: fundJson {
      pricing {
        prices {
          value
          name
        }
        source
        heading
        date
      }
      literatureGroups {
        documents {
          url
          name
        }
        name
      }
    }
  }
`;

export default ({ data }: { data: { fund: FundPageProps } }) => (
    <HomePageTemplate {...data.fund} />
);
